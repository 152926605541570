<template>
    <div>Endosso neutro</div>
  </template>
  <script>
  export default {
    name: "neutro-informacoes-do-endosso",
    components: {},
    data() {
      return {};
    },
    computed: {},
    methods: {},
  };
  </script>
  <style lang="scss"></style>
  